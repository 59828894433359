import React from "react";
import "../../App.css";

export const BlankItScreen = () => {
  return (
    <div className="flex flex-1 justify-center items-center flex-col">
      {/* Logo */}
      <div className="flex justify-center">
        <div className="relative w-1/2 sm:w-3/4 flex justify-center">
          <img
            src="/RectangleBlankIt.png"
            alt="RectangleBlankIt"
            className="w-full"
          />
          <img
            src="/CircleBlankIt.png"
            alt="CircleBlankIt"
            className="absolute inset-0 m-auto w-3/5 animate-scale-circle"
          />
        </div>
      </div>

      <div className="text-blankit-color text-5xl md:text-6xl tracking-wide text-center text-shadow-blankit my-8">
        Blank It!
      </div>

      <div className="bg-blankit-color px-6 py-2 rounded-3xl">
        <div className="text-2xl md:text-3xl font-semibold text-white animate-pulse-slow">
          Coming Soon...
        </div>
      </div>
    </div>
  );
};
