import React from "react";

export const TapFastScreen = () => {
  return (
    <div
      id="tapfast-screen"
      className="flex flex-col sm:flex-row z-10 flex-1 gap-4 sm:gap-4 md:gap-4 lg:gap-24 justify-center"
    >
      {/* Left Part */}
      <div className="flex flex-col justify-center items-center gap-14">
        <img
          src="/tap_fast_logo.png"
          alt="Logo"
          className="w-1/3 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-3/4 flex align-center"
        />
        <div className="flex flex-col justify-center items-center gap-8">
          <img
            src="/content_tap_fast.png"
            alt="Logo"
            className="w-1/2 sm:w-full"
          />
          <img
            src="/content_tap_fast_2.png"
            alt="Logo"
            className="w-1/2 sm:w-full"
          />
        </div>
      </div>
      {/* Phone */}
      <div className="flex-col justify-center items-center hidden sm:flex md:flex lg:flex">
        <img
          src="/TapFast_Mobile.png"
          alt="Logo"
          className="sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-4/5"
        />
      </div>
      {/* Download */}
      <div className="flex flex-col justify-center gap-12 items-center">
        <img
          src="/Download_it_Now.png"
          alt="Logo"
          width="70%"
          className="w-1/2 sm:w-4/5 md:w-3/4"
        />
        <div className="flex gap-8">
          <a
            href="https://play.google.com/store/apps/details?id=com.imadeabug.tapfast&pcampaignid=web_share"
            target="_blank"
            rel="noopener noreferrer"
            className="sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-full h-full hover:cursor-pointer hover:scale-105 transition-all duration-300 active:scale-100"
          >
            <img
              src="/GooglePlayBadge.svg"
              alt="GooglePlay"
              className="sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-full h-full hover:cursor-pointer hover:scale-105 transition-all duration-300 active:scale-100"
            />
          </a>
          <a
            href="https://apps.apple.com/pt/app/tap-fast/id6502991462?l=en-GB"
            target="_blank"
            rel="noopener noreferrer"
            className="sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-full h-full hover:cursor-pointer hover:scale-105 transition-all duration-300 active:scale-100"
          >
            <img
              src="/AppStoreBadge.svg"
              alt="AppStore"
              className="sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-full h-full hover:cursor-pointer hover:scale-105 transition-all duration-300 active:scale-100"
            />
          </a>
        </div>
      </div>
    </div>
  );
};
