import React from "react";

export const CasualSweeperScreen = () => {
  return (
    <div className="flex flex-1 flex-col relative">
      <div className="flex justify-between">
        <div className="flex justify-start absolute top-0 left-0">
          <img
            src="/casual_tl.png"
            alt="casual_tl"
            className="w-1/2 2xl:w-2/3"
          />
        </div>
        <div className="flex justify-end absolute top-0 right-0">
          <img
            src="/casual_tr.png"
            alt="casual_tr"
            className="w-1/2 2xl:w-2/3"
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 flex-1 items-center justify-center">
        <div className="flex justify-center">
          <img src="/casual_bomb.png" alt="casual_bomb" className="w-32" />
        </div>
        <div className="flex justify-center">
          <div className="bg-casual-color px-6 py-2 rounded-3xl">
            <div className="text-2xl md:text-3xl text-gray-500 animate-pulse-slow">
              Coming Soon...
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-start absolute bottom-0 left-0">
        <img src="/casual_bl.png" alt="casual_bl" className="w-1/2 2xl:w-2/3" />
      </div>
      <div className="flex justify-end absolute bottom-0 right-0">
        <img src="/casual_br.png" alt="casual_bl" className="w-1/2 2xl:w-2/3" />
      </div>
    </div>
  );
};
