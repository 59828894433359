import React from "react";
import { ContactForm } from "../../components/contactForm";

export const AboutUs = () => {
  return (
    <div className="flex z-10 flex-1 h-full w-full overflow-hidden items-center justify-center">
      <div>
        <ContactForm />
      </div>
    </div>
  );
};
