import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Pagination } from "swiper/modules";

interface IGames {
  game: String;
  component: JSX.Element;
}

// Optional Swiper modules
export const SwiperCarousel = ({
  data,
  onSlideChange,
}: {
  data: IGames[];
  onSlideChange: any;
}) => {
  return (
    <Swiper
      modules={[Navigation, Pagination]}
      navigation
      pagination={{ clickable: true }}
      spaceBetween={50}
      slidesPerView={1}
      onSlideChange={(swiper: any) => onSlideChange(swiper.activeIndex)}
    >
      {data.map((item, index) => (
        <SwiperSlide key={index} className="!flex">
          {item.component}
        </SwiperSlide>
      ))}
      {/* Add more SwiperSlide components as needed */}
    </Swiper>
  );
};
