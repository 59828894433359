import { MouseEventHandler, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

import { routes } from "../../utils/routes";

export const Navbar = ({ handleBackgroundChange }: any) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const menuVariants = {
    hidden: { opacity: 0, y: -20, scale: 0.95 },
    visible: { opacity: 1, y: 0, scale: 1 },
  };

  return (
    <nav className="bg-gray-800/50 text-white p-6 z-20 absolute top-0 left-0 right-0 shadow-lg">
      <div className="flex justify-between items-center">
        {/* Logo */}
        <Link
          to="/"
          className="flex items-center"
          onClick={handleBackgroundChange}
        >
          <img src="/imab_logo_green.png" alt="Logo" className="h-10 mr-2" />
        </Link>

        {/* Burger Icon */}
        <div className="lg:hidden">
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="flex flex-col justify-between h-6 w-6 focus:outline-none"
          >
            <span
              className={`block w-6 h-0.5 bg-white transition-transform ${
                menuOpen ? "transform rotate-45 translate-y-2" : ""
              }`}
            ></span>
            <span
              className={`block w-6 h-0.5 bg-white transition-opacity ${
                menuOpen ? "opacity-0" : ""
              }`}
            ></span>
            <span
              className={`block w-6 h-0.5 bg-white transition-transform ${
                menuOpen ? "transform -rotate-45 -translate-y-2" : ""
              }`}
            ></span>
          </button>
        </div>

        {/* Navigation Links */}
        <motion.div
          className="lg:flex lg:gap-8 flex-col lg:flex-row items-center absolute lg:relative top-full right-0 w-1/3 lg:w-auto bg-gray-800 lg:bg-transparent p-4 lg:p-0 shadow-lg lg:shadow-none rounded-md"
          initial="hidden"
          animate={menuOpen || window.innerWidth >= 1024 ? "visible" : "hidden"}
          variants={menuVariants}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          style={{
            display: menuOpen || window.innerWidth >= 1024 ? "flex" : "none",
          }}
        >
          {routes.map((route) => (
            <Link
              key={route.path}
              to={route.path}
              className={`hover:underline text-lg font-semibold ${
                location.pathname === route.path ? "text-green-400" : ""
              } hover:scale-105 active:scale-100 transition-transform duration-200 mb-4 lg:mb-0`}
              onClick={() => {
                setMenuOpen(false);
                if (route.path === "/games") {
                  handleBackgroundChange(0);
                } else {
                  handleBackgroundChange();
                }
              }}
            >
              {route.name}
            </Link>
          ))}
        </motion.div>
      </div>
    </nav>
  );
};
