import React from "react";

export const Home = ({ handleBackgroundChange }: any) => {
  return (
    <div className="flex flex-col flex-1 z-10 gap-4">
      <div className="flex flex-1 gap-2 items-end text-white font-extrabold">
        <div className="text-8xl uppercase">I made</div>
        <img src="/imab_logo_green.png" alt="Logo" className="h-20" />
        <div className="text-8xl uppercase">Bug</div>
      </div>
      <div className="flex flex-1 justify-end gap-2">
        <div className="flex justify-end text-3xl uppercase text-white font-extrabold">
          Indie
        </div>
        <div className="flex justify-end text-3xl uppercase text-green-indie font-extrabold">
          Game
        </div>
        <div className="flex justify-end text-3xl uppercase text-white font-extrabold">
          Studio
        </div>
      </div>
    </div>
  );
};
