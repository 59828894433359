export const AtaxxScreen = () => {
  return (
    <div className="flex flex-1 justify-center items-center flex-col">
      <div className="flex justify-center pb-16">
        <div className="relative w-1/2 sm:w-3/4 flex justify-center">
          <img src="/ChipRed.png" alt="ChipRed" className="w-full" />
          <img
            src="/ChipBlue.png"
            alt="ChipBlue"
            className="absolute top-[30%] left-[30%]"
          />
        </div>
      </div>

      <div className="flex justify-center">
        <img
          src="/AtaxxBoardsGroup.png"
          alt="AtaxxBoardsGroup"
          // className="w-1/3 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-3/4 flex align-center"
          className="w-3/4 sm:w-3/4"
        />
      </div>
      <div className="text-2xl md:text-4xl font-semibold text-white animate-pulse-slow">
        Coming Soon...
      </div>
    </div>
  );
};
